.show-loading-animation.rect-shape,
.show-loading-animation.round-shape,
.show-loading-animation.text-row,
.show-loading-animation .rect-shape,
.show-loading-animation .round-shape,
.show-loading-animation .text-row {
  animation: react-placeholder-pulse 0.75s infinite;
}

@keyframes react-placeholder-pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
