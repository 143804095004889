@font-face {
  font-family: 'KPMG';
  src: url('./fonts/kpmg.woff2') format('woff2'),
    url('./fonts/kpmg.woff') format('woff'),
    url('./fonts/kpmg.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'KPMG Logo';
  src: url('./fonts/kpmglogo.woff2') format('woff2'),
    url('./fonts/kpmglogo.woff') format('woff'),
    url('./fonts/kpmglogo.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

html,
body {
  height: 100%;
  font-family: sans-serif;
  background-color: #fff;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
  padding: 10px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .toggleMultiSelect .filter {
    width: 575px;
  }

  .content {
    overflow-x: hidden;
  }
}

.footer {
  flex-shrink: 0;
  text-align: center;
}
.footer_sticky {
  bottom: 0;
  position: fixed;
  width: 100%;
}

/* these need to be here due to issues with the styling of the current version of react slick */
.slick-prev {
  margin-left: -39px;
}

.slick-next {
  margin-right: -11px;
}

.slick-arrow::before {
  display: none;
}

.slick-dots {
  bottom: 8px;
}

.slick-dots li {
  width: 8px;
}

.slick-track {
  margin-left: 0px !important;
}

.search-field .MuiAutocomplete-endAdornment {
  display: none;
}

.search-field .MuiInputBase-root::before {
  display: none !important;
}

.search-field .MuiInputBase-root::after {
  display: none !important;
}

input::-ms-clear {
}

.noborder,
.noborder div,
.noborder div div {
  border-radius: 0 !important;
}

div#gridLoader span span {
  border-radius: 0 !important;
}

::-ms-clear {
}
